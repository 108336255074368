<template>
    <v-container style="margin-right: -40px;">

        <v-row>

            <v-col class="d-none d-sm-block" sm="1"></v-col>

            <v-col sm="10">
            
                <div
                style="
                max-width: 100%;
                margin: auto;
                padding-bottom: 20px;
                "
                >


                    <v-card style="margin-bottom: 15px; box-shadow: 0 0 18px #0000003d background: #95aaea;
                            background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" elevation="7" 
                        >
                        <div style="padding: 10px" class="overline">
                            <h4>Manual Product</h4>
                        </div>
                    </v-card>

            
                    <v-card style=" widthmargin-bottom: 10px; box-shadow: 0 0 18px #0000003d; border-radius: 5px; padding: 13px" elevation="5">
                        <div>
                            
                            <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                    <div
                                        class="elementor-element elementor-element-d3c92f0 elementor-widget elementor-widget-text-editor"
                                        data-id="d3c92f0"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                        >
                                        
                                        <div class="elementor-widget-container">
                                            <div class="elementor-text-editor elementor-clearfix">
                                            
                                            
                                                <p style="font-size: 15px; margin-top: 10px">
                                                    Product is the place where you upload all your unique products that are for sale, which means you have a lot of products to sell. Use Product if you want to use the Unique Payment Form. 
                                                </p>


                                                <img style="border: 1px solid #c8ced3; margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; max-width: 70%" :src="require(`../assets/menu_product.jpg`)"/>

                                                
                                                <div style="background: #95aaea; padding-top: 5px; padding-bottom: 5px; padding-left: 7px; width: 320px; margin-bottom: 10px;
                                                    background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" 
                                                    >A) Register Product
                                                </div>
                                                <br>



                                                <p style="font-size: 15px;">
                                                    1. This is to add new products in the Dashboard. When you add products, it will create a Unique Payment form. Fill in the related information and click the “Submit” button.
                                                </p>

                                                <img style="border: 1px solid #c8ced3; margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; max-width: 90%" :src="require(`../assets/register_product.jpg`)"/>

                                                <p style="font-size: 15px;">
                                                    2. Below are the details of the elements in the table :
                                                </p>

                                                <div style="margin-top: 30px; border: 1px solid #c8ced3; height: auto; margin-bottom: 50px">
                                                    <v-simple-table
                                                        fixed-header

                                                    >
                                                        <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                            <th class="text-left">
                                                                Item
                                                            </th>
                                                            <th class="text-left">
                                                                Detail
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Product Name
                                                            </th>
                                                            <th class="text-left">
                                                                The name of the product
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Category
                                                            </th>
                                                            <th class="text-left">
                                                                The product can be manage based on category
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Image
                                                            </th>
                                                            <th class="text-left">
                                                                The image of your product
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Description
                                                            </th>
                                                            <th class="text-left">
                                                                The explanation about your product and why it is worth purchasing
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Price
                                                            </th>
                                                            <th class="text-left">
                                                                The price of your product
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Tax
                                                            </th>
                                                            <th class="text-left">
                                                                The taxes payable per unit of good or service produced
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Delivery charge
                                                            </th>
                                                            <th class="text-left">
                                                                The cost of transporting or delivering your products.
                                                            </th>
                                                            </tr>
                                                        </thead>
                                                        </template>
                                                    </v-simple-table>
                                                </div>

                                                <p style="font-size: 15px;">
                                                    3. Don’t forget to click “Submit” if you have already confirmed all the information you added regarding your product. This will generate your unique payment form.
                                                </p>

                                                <img style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; max-width: 90%" :src="require(`../assets/submit.jpg`)"/>

                                                 <div style="background: #95aaea; padding-top: 5px; padding-bottom: 5px; padding-left: 7px; width: 320px; 
                                                    background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" 
                                                    >B) Product List
                                                </div>
                                                <br>


                                                <p style="font-size: 15px;">
                                                    1. This displays all the products that you have already added in the apps.
                                                </p>

                                                <img style="border: 1px solid #c8ced3; margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; max-width: 90%" :src="require(`../assets/product_list.jpg`)"/>

                                                <p style="font-size: 15px;">
                                                    2. Below are the details of the elements in the table :
                                                </p>
                                                
                                                <div style="margin-top: 30px; border: 1px solid #c8ced3; height: auto; margin-bottom: 50px">
                                                    <v-simple-table
                                                        fixed-header

                                                    >
                                                        <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                            <th class="text-left">
                                                                Item
                                                            </th>
                                                            <th class="text-left">
                                                                Detail
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Name
                                                            </th>
                                                            <th class="text-left">
                                                                The name of the product
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Description
                                                            </th>
                                                            <th class="text-left">
                                                                The explanation about your product and why it is worth purchasing
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Price
                                                            </th>
                                                            <th class="text-left">
                                                                The price of your product
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Tax
                                                            </th>
                                                            <th class="text-left">
                                                                The taxes payable per unit of good or service produced
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Delivery charge
                                                            </th>
                                                            <th class="text-left">
                                                                The cost of transporting or delivering your products.
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Status
                                                            </th>
                                                            <th class="text-left">
                                                                The status whether your product is available or not available
                                                            </th>
                                                            </tr>
                                                            <tr>
                                                            <th class="text-left">
                                                                Operation
                                                            </th>
                                                            <th class="text-left">
                                                                The operation to edit your product or create URL product
                                                            </th>
                                                            </tr>
                                                        </thead>
                                                        </template>
                                                    </v-simple-table>
                                                </div>

                                                <p style="font-size: 15px;">
                                                    3. Below are the operation for enable and disable product :
                                                </p>

                                                <img style="border: 1px solid #c8ced3; margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; max-width: 90%" :src="require(`../assets/enable-product.jpg`)"/>


                                                <p style="font-size: 15px;">
                                                    4. Below are the operation for edit product and create URL :
                                                </p>

                                                <img style="border: 1px solid #c8ced3; margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; max-width: 90%" :src="require(`../assets/operation.jpg`)"/>


                                                <p style="font-size: 15px;">
                                                    5. Click create URL to get your product URL and copy the link for your own usage:
                                                </p>


                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="border: 1px solid #c8ced3; max-width: 70%" :src="require(`../assets/product_url.jpg`)"/>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-card>
            
                </div>
            </v-col>

            <v-col class="d-none d-sm-block" sm="1"></v-col>



        </v-row>

</v-container>
</template>

<script>
    export default {

    }
</script>

<style scoped>
.profile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 40px;
    color: rgb(167, 167, 167);
    font-weight: 600;
}
</style>